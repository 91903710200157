import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import logo from "../images/logo (1).svg";

const LaravelDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div>
        {/* Navbar Start */}
        <div className="container-fluid position-relative p-0">
          <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
            <Link to="/" className="navbar-brand p-0">
              <img src={logo} style={{ width: "12rem" }} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto py-0">
                <Link to="/" className="nav-item nav-link ">
                  Home
                </Link>
                <Link to="/about" className="nav-item nav-link">
                  About
                </Link>
                <div className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Services
                  </Link>
                  <div className="dropdown-menu m-0">
                    <Link to="/web-design" className="dropdown-item">
                      Web Design
                    </Link>
                    <Link to="/data-analytics" className="dropdown-item">
                      Data Analytics
                    </Link>
                    <Link to="/web-development" className="dropdown-item">
                      Web Development
                    </Link>
                    <Link to="/app-development" className="dropdown-item">
                      Apps Development
                    </Link>
                    <Link to="/seo-optimization" className="dropdown-item">
                      SEO Optimization
                    </Link>
                    <Link to="/custome-development" className="dropdown-item">
                      Custome Development
                    </Link>
                    <Link to="/devs-ops" className="dropdown-item">
                      DevsOps
                    </Link>
                    <Link to="/frontend-development" className="dropdown-item">
                      Frontend Development
                    </Link>
                    <Link to="/backend-development" className="dropdown-item">
                      Backend Development
                    </Link>
                    <Link to="/iot-solutions" className="dropdown-item">
                      Iot- Solutions
                    </Link>
                  </div>
                </div>
                <Link to="/portfolio" className="nav-item nav-link">
                  Portfolio
                </Link>
                <div className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Technologies
                  </Link>
                  <div className="dropdown-menu m-0">
                    <Link to="/react-js" className="dropdown-item">
                      React JS
                    </Link>
                    <Link to="/next-js" className="dropdown-item">
                      Next JS
                    </Link>
                    <Link to="/react-native" className="dropdown-item">
                      React Native
                    </Link>
                    <Link to="/python-development" className="dropdown-item">
                      Python Development
                    </Link>
                    <Link to="/java-development" className="dropdown-item">
                      Java Development
                    </Link>
                    <Link to="/android-development" className="dropdown-item">
                      Android Development
                    </Link>
                    <Link to="/angularjs-development" className="dropdown-item">
                      Angular Js Development
                    </Link>
                    <Link to="/django-development" className="dropdown-item">
                      Django Development
                    </Link>
                    <Link to="/ios-development" className="dropdown-item">
                      Ios Development
                    </Link>
                    <Link to="/laravel-development" className="dropdown-item">
                      Laravel Development
                    </Link>
                  </div>
                </div>

                <Link to="/contact" className="nav-item nav-link">
                  Contact
                </Link>
              </div>
            </div>
          </nav>
          <div
            className="container-fluid bg-primary py-5 bg-header"
            style={{ marginBottom: 90 }}
          >
            <div className="row py-5">
              <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                <h1 className="display-4 text-white animated zoomIn">
                  Laravel Development
                </h1>
              </div>
            </div>
          </div>
        </div>
        {/* Navbar End */}

        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h1 className="mb-0">Laravel Development</h1>
                </div>
                <p className="mb-4">
                  Reimagine your online applications with WebJob Technologies,
                  your go-to source for Laravel development. Our skilled team
                  uses Laravel's capabilities to build dependable, scalable, and
                  high-performing solutions. From flawless backend development
                  to captivating user interfaces, we place a high value on
                  productivity and creativity. WebJob Technologies is an expert
                  at providing Laravel solutions that improve your online
                  visibility. Put your faith in us to bring your ideas to life
                  while providing a productive and efficient development
                  process. WebJob Technologies offers the best Laravel
                  development experience, combining creativity and experience to
                  make your web apps stand out in the ever-changing digital
                  landscape. As your dependable partner, WebJob Technologies
                  will help you navigate the future of web development.
                </p>

                <a
                  href="quote.html"
                  className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  ENQUIRY NOW
                </a>
              </div>
              <div className="col-lg-5" style={{ minHeight: 500 }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="/static/img/6.png"
                    style={{ objectFit: "cover" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LaravelDevelopment;
