import img1 from "../images/CRM.png";
import img2 from "../images/website-Design-and-development.png"

const data = [ 
    {
        id: 1,
        title : "CRM Migration",
        content : "facilitates the transfer of data from one format or application to another for corporations and businesses. We provide planning, migration, and post-migration support for your data migration project and guarantee its success with no failures at all. Our professionals give clean migration services with no delinquencies at all their utmost attention.",
        val: "data-migration",
        cate1: "Cloud migration",
        cate2: "Software migration",
        cate3: "Server migration",
        cate4: "Website migration",
        cate5: "Database migration",
        cate7: "Enterprise platform migration",
        image: {img1}
    }, 
    {
        id: 2,
        title : "Website Design and Development",
        content : "Your idea will flourish more than you anticipated thanks to the stunning design, engaging user interface, and attention-grabbing appearance, and our team of website designers and developers will help ensure that prosperity. The best designs for your creative idea will be coded by our skilled website developers. Because our team of designers has years of experience working with various consumer engagement models, they will be able to better curate your design.",
        val: "website-design-and-development",
        cate1: "Website Development from Scratch",
        cate2: "Software migration",
        cate3: "Server migration",
        cate4: "Website migration",
        cate5: "Database migration",
        cate6: "Enterprise platform migration",
        image: {img2}
    }, 


]


export default data;