import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import logo from "../images/logo (1).svg";
import img1 from "../images/banner-1.jpg";
import img2 from "../images/banner-2.jpg";
import Facts from "./Facts";
import About from "./About";
import Carousel from "./Carousel";
import WhyChooseUs from "./WhyChooseUs";
import Services from "./Services";
import Price from "./Price";
import QuoteStart from "./QuoteStart";
import BlogBoxs from "./BlogBoxs";
// import BannerSlider from "./test/BannerSlider";
import VenderLogos from "./VenderLogos"

// import Slider from "react-slick";


const Test = () => {
  return (
    <>
     {/* <nav className="navbar navbar-expand-lg navbar-dark px-2 py-1 py-lg-0">
        <Link to="/" className="navbar-brand p-0 banner-l">
          <img src={logo} style={{ width: "12rem" }} alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0 bar-l">
            <Link to="/" className="nav-item nav-link active">
              Home
            </Link>
            <Link
              to="/about"
              className="nav-item nav-link color-b"
              style={{
                color: "#000",
              }}
            >
              About
            </Link>
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                style={{
                  color: "#000",
                }}
              >
                Services
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/web-design" className="dropdown-item">
                  Web Design
                </Link>
                <Link to="/data-analytics" className="dropdown-item">
                  Data Analytics
                </Link>
                <Link to="/web-development" className="dropdown-item">
                  Web Development
                </Link>
                <Link to="/app-development" className="dropdown-item">
                  Apps Development
                </Link>
                <Link to="/seo-optimization" className="dropdown-item">
                  SEO Optimization
                </Link>
                <Link to="/custome-development" className="dropdown-item">
                  Custome Development
                </Link>
                <Link to="/devs-ops" className="dropdown-item">
                  DevsOps
                </Link>
                <Link to="/frontend-development" className="dropdown-item">
                  Frontend Development
                </Link>
                <Link to="/backend-development" className="dropdown-item">
                  Backend Development
                </Link>
                <Link to="/iot-solutions" className="dropdown-item">
                  Iot- Solutions
                </Link>
              </div>
            </div>
            <Link
              to="/portfolio"
              className="nav-item nav-link"
              style={{
                color: "#000",
              }}
            >
              Portfolio
            </Link>
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                style={{
                  color: "#000",
                }}
              >
                Technologies
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/react-js" className="dropdown-item">
                  React JS
                </Link>
                <Link to="/next-js" className="dropdown-item">
                  Next JS
                </Link>
                <Link to="/react-native" className="dropdown-item">
                  React Native
                </Link>
                <Link to="/python-development" className="dropdown-item">
                  Python Development
                </Link>
                <Link to="/java-development" className="dropdown-item">
                  Java Development
                </Link>
                <Link to="/android-development" className="dropdown-item">
                  Android Development
                </Link>
                <Link to="/angularjs-development" className="dropdown-item">
                  Angular Js Development
                </Link>
                <Link to="/django-development" className="dropdown-item">
                  Django Development
                </Link>
                <Link to="/ios-development" className="dropdown-item">
                  Ios Development
                </Link>
                <Link to="/laravel-development" className="dropdown-item">
                  Laravel Development
                </Link>
              </div>
            </div>

            <Link
              to="/contact"
              className="nav-item nav-link"
              style={{
                color: "#000",
              }}
            >
              Contact
            </Link>
          </div>
        </div>
      </nav>
      <div className="container-fluid position-relative p-0">
        <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="w-100"
                src="/static/img/banner-1.jpg"
                alt="pic-3"
              />
              <div className="carousel-caption d-flex flex-column">
                <div className="p-3 mar-1" style={{ maxWidth: 900 }}>
                  <h5 className="text-dark text-uppercase mb-3 animated slideInDown ban-h1">
                    Creative &amp; Innovative
                  </h5>
                  <h3 className="display-5 text-dark mb-md-4 animated zoomIn">
                    Creative &amp; Innovative <br /> Digital Solution
                  </h3>
                  <Link
                    to="/contact"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft button"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>


              


            </div>
            <div className="carousel-item">
              <img
                className="w-100"
                src="/static/img/banner-2.jpg"
                alt="pic-4"
              />
              <div className="carousel-caption d-flex flex-column">
                <div className="p-3 mar-1" style={{ maxWidth: 900 }}>
                  <h5 className="text-dark text-uppercase mb-3 animated slideInDown">
                    Creative &amp; Innovative
                  </h5>
                  <h3 className="display-5 text-dark mb-md-4 animated zoomIn">
                    Creative &amp; Innovative <br /> Digital Solution
                  </h3>
                  <Link
                    to="/contact"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft button"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div> */}

      {/* <BannerSlider /> */}
      {/* <SliderBannerLayout /> */}
      <VenderLogos />
    </>
  );
};

export default Test;
