import React from "react";
import Slider from "react-slick";
import "../components/css/services.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img from "../images/CRM.png";
import img2 from "../images/Data-migration.png";
import img3 from "../images/Digital-Promotion.png";
import img4 from "../images/Iot-development.png";
import img5 from "../images/Mobile-app-development.png";
import img6 from "../images/graphic-desing.png";
import img7 from "../images/quality-assurance.png";
import img8 from "../images/website-Design-and-development.png";
import Ecom from "../images/online-shop1.png"
import Health from "../images/healthcare.png";
import Transport from "../images/delivery.png";
import Travel from "../images/travel-and-tourism.png";
import Estate from "../images/buildings.png";
import Education from "../images/online-learning.png";
import { Link } from "react-router-dom";

const Services = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {/* <div className="txt">
        <Slider {...settings}>
          {dataDigitalBestSeller.map((item) => (
            <div className="card">
              <div className="card-top">
                <img src={item.linkImg} alt={item.title} />
                <h1>{item.title}</h1>
              </div>
              <div className="card-bottom">
                <h3>{item.price}</h3>
                <p>{item.category}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div> */}

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: 600 }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Our Services
            </h5>
            <h1 className="mb-0">
              Custom IT Solutions for Your Successful Business
            </h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <img src={Ecom} style={{
                    height:"60px",
                    width:"60px"
                  }} alt="" />
                </div>
                <h4 className="mb-3">Ecommerce</h4>
                <p className="m-0">
                With our heartfelt e-commerce app solutions, you can boost sales growth and interact with your valued customers a bit more.
                </p>
                <Link className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                <img src={Health} style={{
                    height:"60px",
                    width:"60px"
                  }} alt="" />
                </div>
                <h4 className="mb-3">Health</h4>
                <p className="m-0">
                created with great consideration in order to impede and increase the generosity of data in the health sector.
                </p>
                <Link className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                <img src={Transport} style={{
                    height:"60px",
                    width:"60px"
                  }} alt="" />
                </div>
                <h4 className="mb-3">Transport</h4>
                <p className="m-0">
                Create a robust and dependable transportation network to preserve the reputation of your company.
                </p>
                <Link className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                <img src={Travel} style={{
                    height:"60px",
                    width:"60px"
                  }} alt="" />
                </div>
                <h4 className="mb-3">Travel</h4>
                <p className="m-0">
                Webjob is right there with you while you start your blog or come up with a business concept to support your travels.
                </p>
                <Link className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                <img src={Estate} style={{
                    height:"60px",
                    width:"60px"
                  }} alt="" />
                </div>
                <h4 className="mb-3">Real Estate</h4>
                <p className="m-0">
                Showcase your tactics and be vulnerable in your real estate endeavors more than before.

                </p>
                <Link className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                <img src={Education} style={{
                    height:"60px",
                    width:"60px"
                  }} alt="" />
                </div>
                <h4 className="mb-3">Education
</h4>
                <p className="m-0">
                Your education portal will increase engagement the better it is. Select your development partner carefully.
                </p>
                <Link className="btn btn-lg btn-primary rounded" href="">
                  <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="txt">
        <Slider {...settings}>
          <div className="card">
            <div className="card-top">
              <img src={img} alt="img" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img2} alt="img-2" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img3} alt="img-3" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img4} alt="img-4" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img5} alt="img-5" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img6} alt="img-6" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img7} alt="img-7" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img8} alt="img-8" />
            </div>
          </div>
        </Slider>
      </div> */}
    </>
  );
};

export default Services;
