import React from "react";
import gov1 from "../images/gov1.png";
import gov2 from "../images/gov2.png";
import gov3 from "../images/gov3.png";
import gov4 from "../images/gem-new-logo-v2.png";
import gov5 from "../images/akam-left.png";
import "../components/css/govlogo.css";

const GovLogo = () => {
  return (
    <div className="container">
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-md-3 lside">
          <img src={gov1} className="img" alt="" />
        </div>
        <div className="col-md-3 lsside">
          <img src={gov3} className="img2" alt="" />
        </div>
        <div className="col-md-3 lside">
          <img src={gov2} className="img3" alt="" />
        </div>
        <div className="col-md-3 lside">
          <img src={gov4} className="img3" alt="" />
        </div>
      </div>
    </div>
  );
};

export default GovLogo;
