import React from "react";


const PortTxt = () => {
  return (
    <>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12">
              <div className="section-title position-relative pb-3 mb-5">
                <h1 className="mb-0">
                State of the art technology | Focus on innovation | Fast delivery time
                </h1>
              </div>
              <p className="mb-4">
                Webjob Technologies has in-depth experience in mobile app development, web design, development services, and impeccable e-commerce development services from Source Soft Solutions. Our portfolio includes a wide range of projects that we have completed and turns our clients’ passions into brands.
              </p>
              <div className="row g-0 mb-3">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-4">
          <div className="row g-5">
            <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
              <h4>Wide range of services </h4>
              <p className="mb-0">
              We provide innovative digital promotion, animation services, mobile app development, backend development, software consulting, web development, and sales force development (CRM)
              </p>
            </div>
            <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
              <h4>Skills and experience</h4>
              <p className="mb-0">
              Webjob Technologies  has a team of professionals with years of experience in PHP development, Laravel development, .net development, java, and javascript.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4  wow zoomIn"
          data-wow-delay="0.9s"
          style={{ minHeight: 350 }}
        >
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.1s"
              src="/static/img/feature.jpg"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row g-5">
            <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
              <h4>We design and develop what works.</h4>
              <p className="mb-0">
              We strive to give our customers an edge by building high-performance and powerful web and mobile applications. Check out some of the latest projects as you work to create the next big revolution.
              </p>
            </div>
            <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
              <h4>Partnership with you</h4>
              <p className="mb-0">
              We emphasize “innovative” solutions and engagement models to ensure your projects are delivered on time and smoothly within your estimated budget.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
    </>
  );
};

export default PortTxt;
