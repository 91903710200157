import React, { useState } from 'react'

const QuoteStart = () => {
  const [userData, setUserData] = useState({
    yName: "",
    email: "",
    phone: "",
    message: "",
  });

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;

    setUserData({ ...userData, [name]: value });
  };

  // connect with firebase
  const submitData = async (event) => {
    event.preventDefault();
    const { yName, email, phone, message } = userData;

    if (yName && email && phone && message) {
      const res = await fetch(
        "https://wjt-mails-default-rtdb.firebaseio.com/userDataRecord.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            yName,
            email,
            phone,
            message,
          }),
        }
      );

      if (res) {
        setUserData({
          yName: "",
          email: "",
          phone: "",
          message: "",
        });
        alert("Thank you for your feedback");
      } 
      else {
        alert("Please fill the data");
      } 
    } 
  };
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-7">
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold text-primary text-uppercase">
              Request A Quote
            </h5>
            <h1 className="mb-0">
              Need A Free Quote? Please Feel Free to Contact Us
            </h1>
          </div>
          <div className="row gx-3">
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
              <h5 className="mb-4">
                <i className="fa fa-reply text-primary me-3" />
                Reply within 24 hours
              </h5>
            </div>
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
              <h5 className="mb-4">
                <i className="fa fa-phone-alt text-primary me-3" />
                24 hrs telephone support
              </h5>
            </div>
          </div>
          <p className="mb-4">
          We are one of the leading IT services providers who utilize the latest verticals of technologies so our clients can easily win business challenges. Our professionals ensure our client's business growth beyond the flaws of fluctuating worldwide markets. Our ROI-driven technological insights are highly effective in unleashing the maximum business potential
          </p>
          <div
            className="d-flex align-items-center mt-2 wow zoomIn"
            data-wow-delay="0.6s"
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-phone-alt text-white" />
            </div>
            <div className="ps-4">
              <h5 className="mb-2">Call to ask any question</h5>
              <h4 className="text-primary mb-0">+012 345 6789</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div
            className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
            data-wow-delay="0.9s"
          >
            <form>
              <div className="row g-3">
                <div className="col-xl-12">
                  <input
                    type="text"
                    className="form-control bg-light border-0"
                    placeholder="Your Name"
                    name="yName"
                    value={userData.yName}
                    onChange={postUserData}
                    style={{ height: 55 }}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    className="form-control bg-light border-0"
                    placeholder="Your Email"
                    name="email"
                    value={userData.email}
                    onChange={postUserData}
                    style={{ height: 55 }}
                  />
                </div>
                <div className="col-12">
                <input
                      type="text"
                      className="form-control border-0 bg-light "
                      placeholder="Phone Number"
                      name="phone"
                      value={userData.phone}
                      onChange={postUserData}
                      style={{ height: 55 }}
                    />
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control bg-light border-0"
                    rows={3}
                    placeholder="Message"
                    name="message"
                    value={userData.message}
                    onChange={postUserData}
                    defaultValue={""}
                  />
                </div>
                <div className="col-12">
                  <button className="btn btn-dark w-100 py-3" type="submit" onClick={submitData}>
                    Request A Quote
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default QuoteStart