import "./App.css";
import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import PortfolioPage from "./pages/PortfolioPage";
import ContactPage from "./pages/ContactPage";
import AppDevelopment from "./servicespage/AppDevelopment";
import DataAnalytics from "./servicespage/DataAnalytics";
import SeoOptimization from "./servicespage/SeoOptimization";
import WebDesign from "./servicespage/WebDesign";
import WebDevelopment from "./servicespage/WebDevelopment";
import ReactJs from "./technologiespage/ReactJs";
import JavaDevelopment from "./technologiespage/JavaDevelopment";
import NextJs from "./technologiespage/NextJs";
import ReactNative from "./technologiespage/ReactNative";
import PythonDevelopment from "./technologiespage/PythonDevelopment";
import Test from "./components/Test";
import CustomeDevelopment from "./servicespage/CustomeDevelopment";
import DevsOps from "./servicespage/DevsOps";
import FrontendDevelopment from "./servicespage/FrontendDevelopment";
import BackendDevelopment from "./servicespage/BackendDevelopment";
import IotSolutions from "./servicespage/IotSolutions";
import AndroidDevelopment from "./technologiespage/AndroidDevelopment";
import AngularJsDevelopment from "./technologiespage/AngularJsDevelopment";
import DjangoDevelopment from "./technologiespage/DjangoDevelopment";
import IosDevelopment from "./technologiespage/IosDevelopment";
import LaravelDevelopment from "./technologiespage/LaravelDevelopment";

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/app-development" element={<AppDevelopment />} />
          <Route path="/data-analytics" element={<DataAnalytics />} />
          <Route path="/seo-optimization" element={<SeoOptimization />} />
          <Route path="/web-design" element={<WebDesign />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/custome-development" element={<CustomeDevelopment />} />
          <Route path="/devs-ops" element={<DevsOps />} />
          <Route path="/frontend-development" element={<FrontendDevelopment />} />
          <Route path="/backend-development" element={<BackendDevelopment />} />
          <Route path="/iot-solutions" element={<IotSolutions />} />
          <Route path="/react-js" element={<ReactJs />} />
          <Route path="/next-js" element={<NextJs />} />
          <Route path="/react-native" element={<ReactNative />} />
          <Route path="/python-development" element={<PythonDevelopment />} />
          <Route path="/java-development" element={<JavaDevelopment />} />
          <Route path="/android-development" element={<AndroidDevelopment />} />
          <Route path="/angularjs-development" element={<AngularJsDevelopment />} />
          <Route path="/django-development" element={<DjangoDevelopment />} />
          <Route path="/ios-development" element={<IosDevelopment />} />
          <Route path="/laravel-development" element={<LaravelDevelopment />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
