import React, { useState } from "react";
import { Link } from "react-router-dom";

const ContactTxt = () => {
  const [userData, setUserData] = useState({
    yName: "",
    email: "",
    phone: "",
    message: "",
  });

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;

    setUserData({ ...userData, [name]: value });
  };

  // connect with firebase
  const submitData = async (event) => {
    event.preventDefault();
    const { yName, email, phone, message } = userData;

    if (yName && email && phone && message) {
      const res = await fetch(
        "https://wjt-mails-default-rtdb.firebaseio.com/userDataRecord.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            yName,
            email,
            phone,
            message,
          }),
        }
      );

      if (res) {
        setUserData({
          yName: "",
          email: "",
          phone: "",
          message: "",
        });
        alert("Thank you for your feedback");
      } 
      else {
        alert("Please fill the data");
      } 
    } 
  };

  return (
    <>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: 600 }}
          >
            <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
            <h1 className="mb-0">
              If You Have Any Query, Feel Free To Contact Us
            </h1>
          </div>
          <div className="row g-5 mb-5">
            <div className="col-lg-4">
              <div
                className="d-flex align-items-center wow fadeIn"
                data-wow-delay="0.1s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: 60, height: 60 }}
                >
                  <i className="fa fa-phone-alt text-white" />
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h5 className="text-primary mb-0">+91 9958956422 </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="d-flex align-items-center wow fadeIn"
                data-wow-delay="0.4s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: 60, height: 60 }}
                >
                  <i className="fa fa-envelope-open text-white" />
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Email to get free quote</h5>
                  <h5 className="text-primary mb-0">
                    <Link to="mailto:info@webjobtechnologies.com">
                      info@webjobtechnologies.com
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="d-flex align-items-center wow fadeIn"
                data-wow-delay="0.8s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: 60, height: 60 }}
                >
                  <i className="fa fa-map-marker-alt text-white" />
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Visit our office</h5>
                  <h5 className="text-primary mb-0">
                    20417 Hillside Ave, Hollis, NY 11423
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-12 wow slideInUp" data-wow-delay="0.3s">
              <form method="POST">
                <div className="row g-3">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your Name"
                      name="yName"
                      value={userData.yName}
                      onChange={postUserData}
                      style={{ height: 55 }}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your Email"
                      name="email"
                      value={userData.email}
                      onChange={postUserData}
                      style={{ height: 55 }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Phone Number"
                      name="phone"
                      value={userData.phone}
                      onChange={postUserData}
                      style={{ height: 55 }}
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control border-0 bg-light px-4 py-3"
                      rows={4}
                      placeholder="Message"
                      name="message"
                      value={userData.message}
                      onChange={postUserData}
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                      onClick={submitData}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}
    </>
  );
};

export default ContactTxt;
