import React, { useState } from "react";
import Slider from "react-slick";
import "../components/css/services.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img from "../images/CRM.png";
import img2 from "../images/Data-migration.png";
import img3 from "../images/Digital-Promotion.png";
import img4 from "../images/Iot-development.png";
import img5 from "../images/Mobile-app-development.png";
import img6 from "../images/graphic-desing.png";
import img7 from "../images/quality-assurance.png";
import img8 from "../images/website-Design-and-development.png";
import data from "../components/data";

const Carousel = () => {
  const [item, setItem] = useState(data);

  const filterItem = (cateItem) => {
    const updatedItems = data.filter((curElem) => {
      return curElem.val === cateItem;
    });
    setItem(updatedItems)
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="txt">
        <Slider {...settings}>
          <div className="card">
            <div
              className="card-top"
              
            >
              <img src={img} alt="img" onClick={() => filterItem("data-migration")} style={{
                cursor:"pointer"
              }} />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img2} alt="img-2" onClick={ () => console.log("ckekniwjf")} />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img3} alt="img-3" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img4} alt="img-4" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img5} alt="img-5" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img6} alt="img-6" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img7} alt="img-7" />
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <img src={img8} alt="img-8"  />
            </div>
          </div>
        </Slider>
      </div>

      {/* <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        {item.map((elem) => {
          const {
            id,
            title,
            content,
            cate1,
            cate2,
            cate3,
            cate4,
            cate5,
            cate7,
            image,
          } = elem;

          return (
            <div className="container" key={id} value={item}>
              <div className="row g-5">
                <div className="col-lg-7">
                  <div className="section-title position-relative pb-3 mb-5">
                    <h5 className="fw-bold text-primary text-uppercase">
                      About Us
                    </h5>
                    <h1 className="mb-0">{title}</h1>
                  </div>
                  <p className="mb-4">{content}</p>
                  <div className="row g-0 mb-3">
                    <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                      <h5 className="mb-3">
                        <i className="fa fa-check text-primary me-3" />
                        {cate1}
                      </h5>
                      <h5 className="mb-3">
                        <i className="fa fa-check text-primary me-3" />
                        {cate2}
                      </h5>
                      <h5 className="mb-3">
                        <i className="fa fa-check text-primary me-3" />
                        {cate7}
                      </h5>
                    </div>
                    <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                      <h5 className="mb-3">
                        <i className="fa fa-check text-primary me-3" />
                        {cate3}
                      </h5>
                      <h5 className="mb-3">
                        <i className="fa fa-check text-primary me-3" />
                        {cate4}
                      </h5>
                      <h5 className="mb-3">
                        <i className="fa fa-check text-primary me-3" />
                        {cate5}
                      </h5>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mb-4 wow fadeIn"
                    data-wow-delay="0.6s"
                  ></div>
                </div>
                <div className="col-lg-5" style={{ minHeight: 500 }}>
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute w-100 h-100 rounded wow zoomIn"
                      data-wow-delay="0.9s"
                      src={image.img2}
                      style={{ objectFit: "cover" }}
                      alt="pic-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default Carousel;
