import React from "react";
import "../components/css/price.css"

const Price = () => {
  return (
    <>
      {/* Pricing Plan Start */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: 600 }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Pricing Plans
            </h5>
            <h1 className="mb-0">
              We are Offering Competitive Prices for Our Clients
            </h1>
          </div>
          <div className="row g-0">
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div className="bg-light rounded">
                <div className="border-bottom py-4 px-5 mb-4">
                  <h4 className="text-primary mb-1">Basic Website Package</h4>
                  <small className="text-uppercase">
                    Suitable for personal websites/new Enterpreneurs
                  </small>
                </div>
                <div className="p-5 pt-0">
                  <h2 className="display-5 mb-3 lsside" style={{fontSize:"2rem"}}>
                    <small className="align-top">₹</small>
                    5999/-
                    <small className="align-bottom">Only</small>
                  </h2>
                  <div className="d-flex justify-content-between mb-3">
                    <span>5 Page Website Design</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Custom Layout Design</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Contact/Query Form</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>1 Custom Banner Design</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>3 Premium Stock Photos</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Complete Deployment</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>FREE Favicon Design</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Cross Browser Compatible</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>100% Ownership Rights</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>100% Satisfaction Guarantee</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <a href="" className="btn btn-primary py-2 px-4 mt-4">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div
                className="bg-white rounded shadow position-relative"
                style={{ zIndex: 1 }}
              >
                <div className="border-bottom py-4 px-5 mb-4">
                  <h4 className="text-primary mb-1">Startup Website Package</h4>
                  <small className="text-uppercase">
                  Suitable for fresh businesses
                  </small>
                </div>  
                <div className="p-5 pt-0">
                  <h2 className="display-5 mb-3 lsside" style={{fontSize:"2rem"}}>
                    <small className="align-top">₹</small>
                    11,999/-
                    <small className="align-bottom"> Only</small>
                  </h2>
                  <div className="d-flex justify-content-between mb-3">
                    <span>8 Page Website Design</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Custom Layout Design</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Contact/Query Form</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Dedicated Project Manager</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>5 Custom Designed inner pages</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>3 Custom Banner Design</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Fast Load Time</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Cross Browser Compatible</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>100% Ownership Rights</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>100% Satisfaction Guarantee</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <a href="" className="btn btn-primary py-2 px-4 mt-4">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div className="bg-light rounded">
                <div className="border-bottom py-4 px-5 mb-4">
                  <h4 className="text-primary mb-1">Professional Website Package</h4>
                  <small className="text-uppercase">
                  Suitable for large scale businesses
                  </small>
                </div>
                <div className="p-5 pt-0">
                  <h2 className="display-5 mb-3 lsside" style={{fontSize:"2rem"}}>
                    <small className="align-top">₹</small>
                   19,999/-
                    <small className="align-bottom"> Only</small>
                  </h2>
                  <div className="d-flex justify-content-between mb-3">
                    <span>20 Pages Website Design</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Contact/Query/Newsletter Form</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>SEO Friendly Design</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Website Initial Concepts in 48 Hours</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Fast Load Time</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Industry specified Team of Expert Designers and Developers</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Complete Source Files</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>Dedicated Project Manager</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>100% Ownership Rights</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>100% Satisfaction Guarantee</span>
                    <i className="fa fa-check text-primary pt-1" />
                  </div>
                  <a href="" className="btn btn-primary py-2 px-4 mt-4">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pricing Plan End */}
    </>
  );
};

export default Price;
