import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo (1).svg";
import webd from "../images/webd.png";
import "../components/css/banner.css";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark px-2 py-1 py-lg-0">
        <Link to="/" className="navbar-brand p-0 banner-l">
          <img src={logo} style={{ width: "12rem" }} alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0 bar-l">
            <Link to="/" className="nav-item nav-link active">
              Home
            </Link>
            <Link
              to="/about"
              className="nav-item nav-link color-b"
              style={{
                color: "#000",
              }}
            >
              About
            </Link>
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                style={{
                  color: "#000",
                }}
              >
                Services
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/web-design" className="dropdown-item">
                  Web Design
                </Link>
                <Link to="/data-analytics" className="dropdown-item">
                  Data Analytics
                </Link>
                <Link to="/web-development" className="dropdown-item">
                  Web Development
                </Link>
                <Link to="/app-development" className="dropdown-item">
                  Apps Development
                </Link>
                <Link to="/seo-optimization" className="dropdown-item">
                  SEO Optimization
                </Link>
                <Link to="/custome-development" className="dropdown-item">
                  Custome Development
                </Link>
                <Link to="/devs-ops" className="dropdown-item">
                  DevsOps
                </Link>
                <Link to="/frontend-development" className="dropdown-item">
                  Frontend Development
                </Link>
                <Link to="/backend-development" className="dropdown-item">
                  Backend Development
                </Link>
                <Link to="/iot-solutions" className="dropdown-item">
                  Iot- Solutions
                </Link>
              </div>
            </div>
            <Link
              to="/portfolio"
              className="nav-item nav-link"
              style={{
                color: "#000",
              }}
            >
              Portfolio
            </Link>
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                style={{
                  color: "#000",
                }}
              >
                Technologies
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/react-js" className="dropdown-item">
                  React JS
                </Link>
                <Link to="/next-js" className="dropdown-item">
                  Next JS
                </Link>
                <Link to="/react-native" className="dropdown-item">
                  React Native
                </Link>
                <Link to="/python-development" className="dropdown-item">
                  Python Development
                </Link>
                <Link to="/java-development" className="dropdown-item">
                  Java Development
                </Link>
                <Link to="/android-development" className="dropdown-item">
                  Android Development
                </Link>
                <Link to="/angularjs-development" className="dropdown-item">
                  Angular Js Development
                </Link>
                <Link to="/django-development" className="dropdown-item">
                  Django Development
                </Link>
                <Link to="/ios-development" className="dropdown-item">
                  Ios Development
                </Link>
                <Link to="/laravel-development" className="dropdown-item">
                  Laravel Development
                </Link>
              </div>
            </div>

            <Link
              to="/contact"
              className="nav-item nav-link"
              style={{
                color: "#000",
              }}
            >
              Contact
            </Link>
          </div>
        </div>
      </nav>

      <div className="container-fluid py-4">
        <div className="container col-xxl-12 one1">
          <div className="row flex-lg-row-reverse align-items-center">
          <div className="col-lg-7 trns">
              <img
                src={webd}
                className="img-fluid img1"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
            
            <div className="col-lg-5">
              <h1 className="display-6 fw-bold text-body-emphasis mb-3 heading1">
              CREATIVE &  <span style={{ color: "#0B8EB2" }}>INNOVATIVE</span> <br />Digital Solution
                <br />{" "}
              </h1>
              <p className="">
                Quickly design and customize responsive mobile-first sites with
                Bootstrap, the world’s most popular front-end open source
                toolkit.
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-center">
              <Link
                    to="/contact"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft button"
                  >
                    Contact Us
                  </Link>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
