import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import About from "../components/About";
import WhyChooseUs from "../components/WhyChooseUs";
import Services from "../components/Services";
import Price from "../components/Price";
import QuoteStart from "../components/QuoteStart";
import Footer from "../components/Footer";
import Facts from "../components/Facts";
import Carousel from "../components/Carousel";
import GovLogo from "../components/GovLogo";
import VenderLogos from "../components/VenderLogos";

const HomePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  return (
    <>
      <Navbar />
      <Facts />
      <About />
      <Carousel />
      <WhyChooseUs />
      <Services />
      <Price />
      <VenderLogos />
      <QuoteStart />
      <GovLogo />
      <Footer />
    </>
  );
};

export default HomePage;
