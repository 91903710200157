import React from "react";
import clientLogo from "../images/client-logo1.png";

const VenderLogos = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-6">
            <div className="section-title position-relative pb-3 mb-5">
              <h5 className="fw-bold text-primary text-uppercase">
                Our Clients
              </h5>
              <h1 className="mb-0">
                Converting Ideas into Digital Success Stories
              </h1>
            </div>
            <p className="mb-4">
              Discover transformative web solutions designed for your success.
              Our expertise in web technologies ensures a seamless digital
              journey, maximizing your online presence and business potential.
            </p>
           
            <div
              className="d-flex align-items-center mb-4 wow fadeIn"
              data-wow-delay="0.6s"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded"
                style={{ width: 60, height: 60 }}
              >
                <i className="fa fa-phone-alt text-white" />
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                <h4 className="text-primary mb-0">+91 9958956422</h4>
              </div>
            </div>
            <a
              href=""
              className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
              data-wow-delay="0.9s"
            >
              Request A Quote
            </a>
          </div>
          <div className="col-md-6">
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src={clientLogo}
                style={{
                  backgroundSize: "contain",
                  // width:"500px"
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenderLogos;
